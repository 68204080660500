import React, {useRef, useState} from 'react';
import styled from 'styled-components';

import background from './img/background.jpg';
import logo from './img/logo.png';

const Page = styled.div`
  display: flex;
  min-height: 100vh;
  position: relative;
`;

const Background = styled.div`
  width: 50vw;
  max-width: 547px;
  background-image: url(${background});
  background-size: cover;
  background-position: top center;
  
  @media(max-width: 630px) {
    display: none;
  }
`;

const FormWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1e77d3;
`;

const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  padding: 1rem;
  width: 300px;
`;

const Box = styled.div`
  border-radius: 12px;
  background: #fff;
  box-shadow: 2px 4px 10px rgba(0,0,0,.35);
  overflow: hidden;
  padding: .5rem 1rem 1rem;
  margin: 1rem 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: .25rem;
  
  h2 {
    background-color: #e5e6e7;
    color: #5d9ada;
    margin: -.5rem -1rem .25rem;
    padding: .5rem;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: normal;
    grid-column: 1/3;
    text-align: center;
  }
  
  label {
    text-transform: uppercase;
    font-weight: 200;
    font-size: 15px;
    grid-column: 1/3;
  }
  
  input {
    border: 2px solid #ddd;
    padding: .25em .5em;
    font-size: 18px;
  }
  
  input:last-child {
    grid-column: 1/3;
  }
`;

const LogIn = styled.button`
  border-radius: 12px;
  background: #fff;
  box-shadow: 2px 4px 10px rgba(0,0,0,.35);
  border: none;
  text-transform: uppercase;
  color: #1e77d3;
  font-size: 18px;
  font-weight: bold;
  padding: 1em;
  margin-top: .5rem;
  cursor: pointer;
  position: relative;
  top: 0;
  left: 0;
  transition: all .05s;
  
  &:hover {
    top: -2px;
    left: -2px;
    box-shadow: 6px 8px 16px rgba(0,0,0,.35);
  }
  
  &:active {
    top: 1px;
    left: 1px;
    box-shadow: 1px 3px 8px rgba(0,0,0,.35);
  }
`;

const Logo = styled.img.attrs({
    src: logo,
    alt: 'Credit Builder'
})`
  justify-self: center;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Spinner = styled.div`
  display: inline-block;
  width: 64px;
  height: 64px;

  &:after {
    content: " ";
    display: block;
    width: 46px;
    height: 46px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: dual-ring 1.2s linear infinite;
    opacity: .75;
  }
  @keyframes dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Loading = () => (
    <Overlay>
        <Spinner/>
    </Overlay>
);

const ErrorMessage = styled.div`
  background: rgba(255,255,255,.9);
  font-size: 20px;
  color: #900;
  font-weight: bold;
  display: block;
  max-width: 300px;
  border-radius: 12px;
  padding: 1em;
  box-shadow: 2px 4px 12px rgba(0,0,0,.5);
`;

const Error = () => (
    <Overlay>
        <ErrorMessage>
            Please check your card number and password and try again.
        </ErrorMessage>
    </Overlay>
);

function App() {
    const f1 = useRef(null);
    const f2 = useRef(null);
    const password = useRef(null);

    const [v1, setV1] = useState('');
    const [v2, setV2] = useState('');

    const [state, setState] = useState(0);

    const changeHandler = e => {
        const target = e.target;
        const value = (target.value || '').replace(/\D+/g, '');

        let next = null;

        if (target === f1.current) {
            setV1(value);
            next = f2.current;
        }
        else {
            setV2(value);
            next = password.current;
        }

        if (value.length === 4 && next)
            next.focus();
    };

    const submitHandler = e => {
        e.preventDefault();

        setState(1);

        setTimeout(() => {
            setState(2);

            setTimeout(() => {
                setState(0);
            }, 5000);
        }, Math.floor(500 + 2000*Math.random()));
    };

    return (
        <Page>
            <Background/>
            <FormWrapper>
                <Form onSubmit={submitHandler}>
                    <Logo/>
                    <Box>
                        <h2>Welcome To Credit Builder</h2>
                        <label>
                            last eight of your card number
                        </label>
                        <input value={v1} type="password" autoComplete={'off'} maxLength={4} size={4} ref={f1} onChange={changeHandler} required={true} pattern="\d{4}" autoFocus={true}/>
                        <input value={v2} type="password" autoComplete={'off'} maxLength={4} size={4} ref={f2} onChange={changeHandler} required={true} pattern="\d{4}"/>
                        <label>password</label>
                        <input type="password" autoComplete={'off'} ref={password} required={true} minLength={6}/>
                    </Box>
                    <LogIn>log in</LogIn>
                </Form>
            </FormWrapper>
            {state === 1 && <Loading/>}
            {state === 2 && <Error/>}
        </Page>
    );
}

export default App;
